import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CryptoJS from "crypto-js";

const useStyles = makeStyles((theme) => ({
  mediumAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: "45px",
    height: "45px",
  },
  smallAvatar: {
    backgroundColor: theme.palette.secondary.main,
    width: "30px",
    height: "30px",
  },
}));

export default function Gravatar({ size, email }) {
  const classes = useStyles();
  const [avatarStatus, setAvatarStatus] = useState(404);

  useEffect(() => {
    async function getImageStatus() {
      try {
        const res = await fetch(
          `https://www.gravatar.com/avatar/${CryptoJS.MD5(email)}?d=404`
        );
        setAvatarStatus(res.status);
      } catch (err) {
        console.log(err);
      }
    }
    if (email) {
      getImageStatus();
    }
  }, [email]);

  if (!email) {
    return null;
  }

  return (
    <div>
      {avatarStatus === 200 && (
        <Avatar
          alt=""
          className={
            size === "medium" ? classes.mediumAvatar : classes.smallAvatar
          }
          src={`https://www.gravatar.com/avatar/${CryptoJS.MD5(
            email
          )}?s=88&d=identicon`}
        />
      )}
      {avatarStatus === 404 && (
        <Avatar
          className={
            size === "medium" ? classes.mediumAvatar : classes.smallAvatar
          }
          alt=""
        >
          {email?.charAt(0).toUpperCase() || "–"}
        </Avatar>
      )}
    </div>
  );
}
