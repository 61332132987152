import React, { useState } from "react";
import ButtonLink from "components/ButtonLink";
import useTranslation from "next-translate/useTranslation";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Gravatar from "components/Gravatar";
import makeStyles from "@mui/styles/makeStyles";
import { useRouter } from "next/router";
import useUser from "hooks/useUser";

const useStyles = makeStyles({
  titleUsername: {
    textTransform: "capitalize",
    fontSize: "20px",
  },
  organizationTitle: {
    fontSize: "14px",
  },
});

export default function ProfileMenu({ data }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useUser();
  const router = useRouter();
  const { t } = useTranslation("common");

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Box ml={1}>
        <IconButton
          onClick={openMenu}
          size="small"
          sx={{ border: "1px solid #cbd8e1", padding: "2px" }}
        >
          <Gravatar email={user?.email ?? "a"} size="small" />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{ "& .MuiList-root": { paddingBottom: "0px" } }}
        PaperProps={{
          style: {
            maxHeight: "calc(100vh - 62px - 100px)",
            width: "239px",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="column"
          mt={1}
        >
          {data[0].name && (
            <Typography
              className={classes.titleUsername}
              variant="h4"
              sx={{ mt: 1 }}
            >
              {data[0].name}
            </Typography>
          )}
          {user.email && (
            <Box
              width={"100%"}
              px={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
            >
              <Typography variant="body2" noWrap>
                {user.email.split("@")[0]}
              </Typography>
              <Typography variant="body2">
                @{user.email.split("@")[1]}
              </Typography>
            </Box>
          )}
          <Box
            my={3}
            px={3}
            width={"100%"}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Box width={"100%"}>
              <MenuItem
                onClick={handleClose}
                sx={{
                  justifyContent: "center",
                  padding: "0",
                  marginBottom: "8px",
                  "& a": {
                    width: "100%",
                    "& button": {
                      width: "100%",
                    },
                  },
                }}
              >
                <ButtonLink
                  href="/profile"
                  variant="outlined"
                  size="small"
                  color="primary"
                  fullWidth
                >
                  {t("tabs.profile")}
                </ButtonLink>
              </MenuItem>
            </Box>
            <Box width={"100%"}>
              <MenuItem
                onClick={handleClose}
                sx={{
                  justifyContent: "center",
                  padding: "0",
                  "& a": {
                    width: "100%",
                    "& button": {
                      width: "100%",
                    },
                  },
                }}
              >
                <ButtonLink
                  href={`/organizations/${data[0].id}/0`}
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  {data.length > 1 ? t("tabs.accounts") : t("tabs.account")}
                </ButtonLink>
              </MenuItem>
            </Box>
            <Box width={"100%"} mt={1}>
              <MenuItem
                onClick={handleClose}
                sx={{
                  justifyContent: "center",
                  padding: "0",
                  "& a": {
                    width: "100%",
                    "& button": {
                      width: "100%",
                    },
                  },
                }}
              >
                <ButtonLink
                  href="https://help.themecloud.io/"
                  target="_blank"
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  {t("tabs.help")}
                </ButtonLink>
              </MenuItem>
            </Box>
            {user.invitations?.length > 0 && (
              <Box width={"100%"} mt={1}>
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    justifyContent: "center",
                    padding: "0",
                    "& a": {
                      width: "100%",
                      "& button": {
                        width: "100%",
                      },
                    },
                  }}
                >
                  <ButtonLink
                    href="/invitations"
                    target="_blank"
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    {t("invites")}
                  </ButtonLink>
                </MenuItem>
              </Box>
            )}
          </Box>
        </Box>
        <Divider />
        {user.email && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f5f5f5" }}
          >
            <ButtonLink
              href={"/logout"}
              startIcon={<ExitToAppIcon />}
              size="small"
              fullWidth
            >
              {t("logout")}
            </ButtonLink>
          </Box>
        )}
        {/* Users logged in through Symfony should logout through a different way */}
        {!user.email && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={() => router.push("/logout_token")}
              startIcon={<ExitToAppIcon />}
              size="small"
              fullWidth
            >
              {t("logout")}
            </Button>
          </Box>
        )}
      </Menu>
    </>
  );
}
