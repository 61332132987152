import React, { useCallback } from "react";
import { VariantType, useSnackbar } from "notistack";

interface NofiticationContextInterface {
  notify(message: string, variant: VariantType): void;
}

export const NotificationContext =
  React.createContext<NofiticationContextInterface | null>(null);

export default function NotificationProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const notify = useCallback(
    (message, variant: VariantType) => {
      enqueueSnackbar(message, { variant });
    },
    [enqueueSnackbar]
  );

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
    </NotificationContext.Provider>
  );
}
