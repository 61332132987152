import { createContext, useContext } from "react";

const AppContext = createContext();

export function AppWrapper({ children }) {
  const hostName =
    typeof window !== "undefined" && window.location.host
      ? window.location.host
      : "";

  const brand = hostName.includes("faaaster.pro")
    ? "faaasterpro"
    : hostName.includes("vercel")
    ? "faaasterpro"
    : hostName.includes("localhost")
    ? "faaaster"
    : "faaaster";
  let brandState = {
    /* whatever you want */
    brand: brand,
    hostName: hostName,
  };

  return (
    <AppContext.Provider value={brandState}>{children}</AppContext.Provider>
  );
}
export function useAppContext() {
  return useContext(AppContext);
}
