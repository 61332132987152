
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect } from "react";
// import React from "react";

import { AppWrapper } from "../contexts/BrandContext";
import Head from "next/head";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useRouter } from "next/router";
// import "@fontsource/roboto";

import "../lib/firebase";
import theme from "../theme";
import Header from "../components/header";
import QueryClientProvider from "../components/QueryClientProvider";
import NotificationProvider from "../components/NotificationProvider";
import UserProvider from "../components/UserProvider";
import useTranslation from "next-translate/useTranslation";
import PlausibleProvider from "next-plausible";
import { ChargebeeProvider } from "../contexts/ChargebeeContext";
// import "../fonts.css";
// import Router from "next/router";
// import LoadingIndicator from "../components/LoadingIndicator";
import { SnackbarProvider } from "notistack";

const __Page_Next_Translate__ = function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { locale } = router;
  const { t } = useTranslation("common");
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);
  // useEffect(() => {
  //   console.log("Router.asPath", Router.asPath);
  //   if (Router.asPath.includes("cart/0") && Router.asPath.includes("cart/0"))
  //     return;
  //   const handleStart = (url) => url !== Router.asPath && setLoading(true);
  //   const handleComplete = () => setLoading(false);

  //   Router.events.on("routeChangeStart", handleStart);
  //   Router.events.on("routeChangeComplete", handleComplete);
  //   Router.events.on("routeChangeError", handleComplete);

  //   return () => {
  //     Router.events.off("routeChangeStart", handleStart);
  //     Router.events.off("routeChangeComplete", handleComplete);
  //     Router.events.off("routeChangeError", handleComplete);
  //   };
  // }, []);
  return (
    <PlausibleProvider domain="app.faaaster.io">
      <AppWrapper>
        <UserProvider>
          <QueryClientProvider>
            <Head>
              <title>{t("app-title")}</title>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
            </Head>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={3}>
                  <NotificationProvider>
                    <Header />
                    {/* {loading && <LoadingIndicator />} */}
                    <ChargebeeProvider>
                      <Component {...pageProps} />
                    </ChargebeeProvider>
                  </NotificationProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </QueryClientProvider>
        </UserProvider>
      </AppWrapper>
    </PlausibleProvider>
  );
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  