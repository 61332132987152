import { useQuery, UseQueryOptions } from "react-query";

import useUser from "./useUser";
import { FetchError } from "../components/QueryClientProvider";

export default function useAuthenticatedQuery<TQueryFnData = unknown>(
  queryKey: string,
  options: UseQueryOptions<TQueryFnData> = {}
) {
  const { accessToken } = useUser();

  const key = accessToken ? [queryKey, accessToken] : [queryKey];
  return useQuery<TQueryFnData, FetchError>(key, options);
}
