import Script from "next/script";
import useUser from "../hooks/useUser";

const Crisp = () => {
  const user = useUser();
  return (
    <>
      <Script id="crisp-chat" strategy="afterInteractive">
        {`window.$crisp=[];window.CRISP_WEBSITE_ID="1fe3bea1-f6da-47fd-961a-65f50efbfe62";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();window.$crisp.push(["safe", true]);`}
      </Script>
      {user?.email && (
        <>
          <Script id="crisp-chat-email" strategy="afterInteractive">
            {`window.$crisp.push(["set", "user:email", ["${user?.email}"]]); console.log("Crisp user email set to ${user?.email}");`}
            {`window.$crisp.push(["set", "session:data", [[["partner", "${user?.isPartner}"],["education", "${user?.isEdu}"],["subscriptions", "${user?.paidSites}"],["user-id", "${user?.uid}"],["user_id", ""],["plan", ""],["isPartner", ""],["status", ""],["abos", ""]]]]);`}
            {`window.$crisp.push(["set", "session:segments", [["partners"], ${user?.isPartner}]]);`}
            {`window.$crisp.push(["set", "session:segments", [["education"], ${user?.isEdu}]]);`}
            {`window.$crisp.push(["set", "session:segments", [["customer"], ${
              user?.paidSites > 0
            }]]);`}
          </Script>
        </>
      )}

      {/* <Script id="loopedin" strategy="afterInteractive">
        {`var ps_popover = {
          workspace_id : "4ca274ab-8827-4a60-8a88-b3086a8683ff",
          selector: ".logo"
          }`}
      </Script>
      <Script
        type="text/javascript"
        src="https://cdn.loopedin.io/js/popover.min.js?v=0.1"
        defer="defer"
      ></Script> */}
    </>
  );
};
export default Crisp;
