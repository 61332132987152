// context/ChargebeeContext.js

import React, { createContext, useState, useEffect } from "react";

export const ChargebeeContext = createContext();

export const ChargebeeProvider = ({ children }) => {
  const [cbInstance, setCbInstance] = useState(null);

  useEffect(() => {
    const initializeChargebee = () => {
      if (window.Chargebee) {
        // Initialize with site and publishableKey
        window.Chargebee.init({
          site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
          publishableKey: process.env.NEXT_PUBLIC_CHARGEBEE_PUBLISHABLE_KEY,
        });

        // Set cbInstance
        setCbInstance(window.Chargebee.getInstance());
      }
    };

    if (typeof window !== "undefined" && window.Chargebee) {
      initializeChargebee();
    } else {
      const script = document.createElement("script");
      script.src = "https://js.chargebee.com/v2/chargebee.js";
      script.async = true;
      script.onload = initializeChargebee;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <ChargebeeContext.Provider value={cbInstance}>
      {children}
    </ChargebeeContext.Provider>
  );
};
