import React from "react";
import {
  QueryClient,
  QueryClientProvider as BaseQueryClientProvider,
} from "react-query";

export class FetchError extends Error {
  response: Response;
  data: any;

  constructor(msg: string) {
    super(msg);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

export async function fetcher({ queryKey }, options = { headers: {} }) {
  try {
    const finalOptions = queryKey[1]
      ? {
          ...options,
          headers: {
            Authorization: `Bearer ${queryKey[1]}`,
            ...options?.headers,
          },
        }
      : options;
    const response = await fetch(queryKey[0], finalOptions);

    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    const error = new FetchError(response.statusText);
    // console.log(error);
    error.response = response;
    error.data = data;
    throw error;
  } catch (error) {
    if (!error.data) {
      error.data = { message: error.message };
    }
    throw error;
  }
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: fetcher,
      refetchOnWindowFocus: false,
    },
  },
});

export default function QueryClientProvider({ children }) {
  return (
    <BaseQueryClientProvider client={queryClient}>
      {children}
    </BaseQueryClientProvider>
  );
}
