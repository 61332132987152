import { createTheme } from "@mui/material/styles";
import { FilsonPro, Gotham } from "./fonts";
// import { isWhiteSpaceLike } from "typescript";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#00458b",
      light: "#F4F7FA",
    },
    secondary: {
      main: "#3FD2C7",
      light: "#272727",
    },
    success: {
      main: "#55A55E",
    },
    warning: {
      main: "#FFA000",
    },
    error: {
      main: "#C62828",
    },
    lightsuccess: {
      main: "#55A55E30",
    },
    lighterror: {
      main: "#C6282830",
    },
    lightwarning: {
      main: "#FFA00030",
    },
    text: {
      main: "#333",
      default: "#f8fafd",
    },
    background: {
      main: "#f8fafd",
      default: "#f8fafd",
    },
  },
  typography: {
    fontFamily: Gotham.style.fontFamily,
    allVariants: {
      color: "#333",
    },
    a: {
      textDecoration: "none",
      color: "#333",
      "&:hover": {
        color: "#3FD2C7",
      },
    },
    links: {
      textDecoration: "none",
      "&:hover": {
        color: "#3FD2C7",
      },
    },
    body1: {
      fontFamily: Gotham.style.fontFamily,
      fontWeight: 400,
      color: "#333",
      fontSize: 15,
    },
    body2: {
      fontFamily: Gotham.style.fontFamily,
      fontWeight: 400,
      color: "#333",
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: FilsonPro.style.fontFamily,
      fontWeight: 700,
      color: "#272727",
      fontSize: "15px",
      lineHeight: "20px",
    },
    caption: {
      fontFamily: Gotham.style.fontFamily,
      fontWeight: 400,
      color: "#000000DE",
      fontSize: 11,
      letterSpacing: 0,
    },
    h1: {
      fontFamily: FilsonPro.style.fontFamily,
      fontWeight: 800,
      fontSize: 36,
      letterSpacing: 0.1,
    },
    h2: {
      fontFamily: FilsonPro.style.fontFamily,
    },
    h3: {
      fontFamily: FilsonPro.style.fontFamily,
      fontWeight: 700,
      fontSize: "1.6rem",
    },
    h4: {
      fontFamily: FilsonPro.style.fontFamily,
      fontWeight: 700,
      fontSize: "1.3rem",
      color: "#00458b",
    },
    h5: {
      fontFamily: FilsonPro.style.fontFamily,
      fontWeight: 600,
      fontSize: "1rem",
      color: "#00458b",
    },
    h6: {
      fontFamily: Gotham.style.fontFamily,
      fontSize: "0.8rem",
      fontWeight: 400,
      color: "#333",
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          "&:hover .MuiSwitch-switchBase": {
            backgroundColor: "#3fd2c624",
          },
        },
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "body2" },
          style: {
            color: "#333",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "16px",
            "&:hover": {
              color: "#00458b",
            },
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "60px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: FilsonPro.style.fontFamily,
          fontWeight: 600,
          textTransform: "none",
          letterSpacing: 0.2,
          fontSize: "15px",
          lineHeight: "19px",
        },
      },
      variants: [
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            backgroundColor: "#e5f4fd",
            borderColor: "transparent",
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            backgroundColor: "#3FD2C730",
            color: "#00458b",
            borderColor: "transparent",
          },
        },
        {
          props: { variant: "outlined", color: "error", size: "small" },
          style: {
            backgroundColor: "#c628280a",
            borderColor: "transparent",
          },
        },
        {
          props: { variant: "contained" },
          style: {
            transitionDuration: "0.9s !important",
            background: "linear-gradient(160deg, #00458b 37%, #3fd2c7 140%)",
            borderColor: "transparent",
            color: "#fff !important",
            "&:hover": {
              background: "linear-gradient(160deg, #00458b 37%, #3fd2c7 100%)",
            },
          },
        },
        {
          props: { variant: "contained", disabled: true },
          style: {
            opacity: "0.5",
          },
        },
        {
          props: { size: "small" },
          style: {
            fontSize: "14px",
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: FilsonPro.style.fontFamily,
          fontWeight: "600",
          "&:hover": {
            backgroundColor: "#3fd2c624",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: FilsonPro.style.fontFamily,
          fontWeight: "600",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          border: "none",
          boxShadow: "3px 8px 19px 0 #328ec02b",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#00458a6b",
              "&:hover": {
                borderColor: "#00458b",
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // background: "black",
          "& .MuiInputLabel-root": {
            background: "white",
            padding: "0px 5px",
          },
        },
      },
    },
    MuiInputLabel: {
      asterisk: {
        styleOverrides: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f6f6f6",
            border: "1px solid rgb(236 236 236)",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          },

          "& .MuiDataGrid-columnHeader": {
            fontSize: "12px",
          },
          "& .MuiDataGrid-columnHeader--moving": {
            background: "white",
          },

          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f9fffe",
          },
        },
      },
      GridFilterPanel: {
        styleOverrides: {
          root: {
            padding: "16px",
          },
          "& .MuiFormControl-root": {
            marginRight: "8px",
            marginLeft: "8px",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          color: "#00458b",
          "& .MuiTab-labelIcon": {
            flexDirection: "row",
            minHeight: "50px",
            "& .MuiSvgIcon-root": {
              marginRight: "6px",
              marginBottom: "3px",
              fontSize: "20px",
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#3FD2C7",
          },
          "& .Mui-selected": {
            color: "#00458b !important",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: FilsonPro.style.fontFamily,
          fontWeight: "500",
          "& .Mui-selected": {
            color: "#3FD2C7",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableHead-root": {
            backgroundColor: "#e5f4fd",
          },
        },
      },
    },
  },
});

export default theme;
