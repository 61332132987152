import React from "react";
import { Button } from "@mui/material";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

export default function ButtonLink(props) {
  const {
    activeClassName = "active",
    className: classNameProps,
    href,
    children,
    color,
    size,
    variant,
    startIcon,
    fullWidth = false,
    sx,
  } = props;
  const router = useRouter();
  const pathname = typeof href === "string" ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  return (
    <Link href={href} passHref={true}>
      <Button
        color={color}
        size={size}
        variant={variant}
        startIcon={startIcon}
        fullWidth={fullWidth}
        className={className}
        sx={sx}
      >
        {children}
      </Button>
    </Link>
  );
}
